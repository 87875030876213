<template>
  <div class="profile">
    <van-nav-bar
      :title="$t('user.profile.title')"
      fixed
      placeholder
    >
      <template #right>
        <van-icon
          name="setting-o"
          size="24"
          color="#000"
          tag="router-link"
          :to="{ name: 'settings' }"
        />
      </template>
    </van-nav-bar>
    <van-cell class="profile-section">
      <div class="profile-section__header">
        <span class="profile-section__title">{{ $t('profile.username') }}</span>
        <router-link
          :to="{ name: 'changeEmail' }"
          class="profile-section__edit"
        >
          {{ $t('edit') }}
        </router-link>
      </div>
      <div class="profile-section__content">
        {{ email }}
      </div>
    </van-cell>
    <van-cell class="profile-section">
      <div class="profile-section__header">
        <span class="profile-section__title">{{ $t('profile.myData') }}</span>
        <router-link
          :to="{ name: 'changeDiagnosis' }"
          class="profile-section__edit"
        >
          {{ $t('edit') }}
        </router-link>
      </div>
      <div class="profile-section__content">
        <data-table :data="nutritionLimits" />
      </div>
    </van-cell>
    <div class="profile-footer">
      <van-button
        type="plain"
        :text="$t('auth.logout.title')"
        :to="{
          name: 'logout',
          params: {
            track: trackLogoutEvent,
          }
        }"
      />
    </div>
    <div class="footnote">
      Datenbasis: {{ $appVersion }}
    </div>
  </div>
</template>

<script>
import { auth, analytics } from '@/firebase';
import Diagnosis from '@/models/diagnosis';
import DataTable from '@/components/DataTable';
import { getNutritionUnit, isNutritionKeyProtein } from '@/helper';

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      calories: 0,
      diagnosis: null,
      email: null,
      protein: 0,
    };
  },
  computed: {
    nutritionLimits() {
      const limits = [
        {
          label: this.$i18n.t('profile.diagnosis'),
          value: this.diagnosis,
        },
        {
          label: this.$i18n.t('calories'),
          value: `${this.calories} ${getNutritionUnit('calories')}`,
        },
        {
          label: this.$i18n.t('protein'),
          value: this.protein ? `${this.protein} ${getNutritionUnit('protein')}`
            : this.$i18n.t('profile.notSpecified'),
        },
      ];
      if (this.diagnosis.nutritionKey && !isNutritionKeyProtein(this.diagnosis)) {
        limits.push({
          label: this.$i18n.t(`nutritions.${this.diagnosis.nutritionKey}`),
          value: `${this[this.diagnosis.nutritionKey]} ${getNutritionUnit(
            this.diagnosis.nutritionKey,
          )}`,
        });
      }
      if (this.diagnosis.optionalNutritionKeys) {
        this.diagnosis.optionalNutritionKeys.forEach((name) => {
          if (this[name]) {
            limits.push({
              label: this.$i18n.t(`nutritions.${name}`),
              value: `${this[name]} ${getNutritionUnit(name)}`,
            });
          }
        });
      }
      return limits;
    },
  },
  created() {
    Object.assign(this, this.$store.getters['user/nutritionLimits']);
    this.diagnosis = new Diagnosis(this.$store.getters['user/diagnosisName']);
    this.email = auth.currentUser.email;
  },
  methods: {
    trackLogoutEvent() {
      analytics.logEvent('logout', {
        event_category: 'engagement',
        event_label: 'User logged out',
      });
    },
    isNutritionKeyProtein,
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.profile {
  display: grid;
  grid-template-rows: repeat(4, minmax(min-content, max-content)) 1fr;
  flex-grow: 1;
}

.profile-section {
  padding: config.$spacing-sm config.$spacing;
  margin-bottom: config.$spacing-xs;
  background-color: config.$color-light;
}

.profile-section__header {
  display: flex;
  justify-content: space-between;
}

.profile-section__title {
  margin: 0 0 config.$spacing-xxs;
  font-weight: bold;
  line-height: 1.6;
  color: #000a12;
}

.profile-section__title,
.profile-section__edit,
.profile-section__content {
  color: config.$color-darkest;
  font-size: config.$font-size;
}

.profile-footer {
  display: flex;
  justify-content: flex-end;

  .van-button {
    border: none;
    font-size: config.$font-size-sm;
  }
}

.van-nav-bar__placeholder {
  width: 100%;
}

.footnote {
  font-size: config.$font-size-xxs;
  margin-top: auto;
  padding: config.$spacing-sm 0;
  text-align: center;
}
</style>
